import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../services/api.service';
import {
  ColDef,
  CsvExportParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ProcessCellForExportParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { RegLetter, Reception, Others } from './interfaces/grid.interface';

@Component({
  selector: 'app-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss'],
})
export class GridTableComponent implements OnInit {
  gridApi: GridApi;
  type: string;
  rowData: Array<Reception | RegLetter | Others> = [];
  columnDefs: ColDef[] = [];
  theme = 'light';
  totalRowsSelected: number = 0;
  startDate: Date = new Date();
  endDate: Date = new Date();

  defaultColDef = {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
  };

  arrayRenderer(params: ICellRendererParams) {
    let displayedValue: string = '';

    if (Array.isArray(params.value)) {
      params.value.forEach((element: any, i: number) => {
        i == params.value.length - 1
          ? (displayedValue += element.name + ' ' + element.area)
          : (displayedValue += element.name + ' ' + element.area + ', ');
      });
    } else if (typeof params.value === 'object') {
      displayedValue = params.value?.name || '-';
    } else if (typeof params.value === 'string') {
      displayedValue = params.value;
    }

    return displayedValue;
  }

  dateRenderer = function (params: any) {
    if (params.value != null) {
      let dateMoment = moment(params.value + 'Z');
      return dateMoment.format('DD/MM/yyyy HH:mm');
    } else return params.value;
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public apiService: ApiService,
    public cookieService: CookieService,
    public translateService: TranslateService
  ) {
    const lastMonth = this.startDate.getMonth() - 1;
    this.startDate.setMonth(lastMonth);
  }

  ngOnInit() {
    if (this.cookieService.check('theme')) {
      this.theme = this.cookieService.get('theme');
    }

    this.route.paramMap.subscribe(async (params) => {
      this.type = params.get('tableName');
      this.getGridData(this.type);
    });
  }

  async getGridData(tableName: string) {
    switch (tableName) {
      case 'regLetter':
        this.rowData = await this.apiService.getRegLettersByDate(
          this.startDate,
          this.endDate
        );
        this.prepareRegletterData();
        break;
      case 'reception':
        this.rowData = await this.apiService.getReceptionsByDate(
          this.startDate,
          this.endDate
        );
        this.prepareReceptionsData();
        break;
      case 'others':
        this.rowData = await this.apiService.getOthersByDate(
          this.startDate,
          this.endDate
        );
        this.prepareOthersData();
        break;
    }
    this.initData();
  }

  initData() {
    if (this.rowData.length) {
      this.columnDefs = [];

      for (const [key, value] of Object.entries(this.rowData[0])) {
        if (Array.isArray(value) || typeof value === 'object')
          this.columnDefs.push({
            field: key,
            cellRenderer: this.arrayRenderer,
          });
        else if (key.includes('Date') || key.includes('date'))
          this.columnDefs.push({
            field: key,
            cellRenderer: this.dateRenderer,
          });
        else this.columnDefs.push({ field: key });
      }
      // Remove Id as is unnecessary
      this.columnDefs.shift();
    }
  }

  prepareRegletterData() {
    this.rowData = (this.rowData as RegLetter[]).map((rowInfo: RegLetter) => {
      delete rowInfo['batchNumber'];
      return rowInfo;
    });
    this.rowData.sort((first, second): number => {
      return (first as RegLetter).pickingDate <
        (second as RegLetter).pickingDate
        ? 1
        : -1;
    });
  }

  prepareReceptionsData() {
    this.rowData.sort((first, second): number => {
      return (first as Reception).receptionDate <
        (second as Reception).receptionDate
        ? 1
        : -1;
    });
  }

  prepareOthersData() {
    this.rowData.sort((first, second): number => {
      return (first as Others).date < (second as Others).date ? 1 : -1;
    });
  }

  navigate(route: string) {
    this.router.navigate(['/data', route]);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
  }

  onSelectionChanged(params: SelectionChangedEvent) {
    const selectedRows = params.api.getSelectedRows();
    this.totalRowsSelected = selectedRows.length;
  }

  exportToCSV(onlySelected: boolean = false) {
    const exportParams: CsvExportParams = {
      allColumns: !onlySelected,
      onlySelected: onlySelected,
      fileName: `${new Date().toDateString()}.csv`,
      /*
        This function is needed because of cellrenderers aren't exported to csv
        It is called for each cell and return the value to save in the csv
      */
      processCellCallback: (params: ProcessCellForExportParams) => {
        const { value } = params;

        if (Array.isArray(value)) {
          return value.length === 0 ? '-' : value[0].name;
        }

        if (typeof value === 'object' && value !== null) {
          return value.name;
        }

        if (value == null || value === '') return '-';

        return value;
      },
    };

    this.gridApi.exportDataAsCsv(exportParams);
  }

  onDateInput(type: string, event: MatDatepickerInputEvent<Date>) {
    type === 'start'
      ? (this.startDate = event.value)
      : (this.endDate = event.value);
    this.getGridData(this.type);
  }
}
