import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss']
})
export class ProgressModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
