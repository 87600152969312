<div class="window" fxLayout="column" fxLayoutGap="20px" fxFlexFill>
    <app-header></app-header>
    <div class="main-container">
        <h1 class="animate__animated animate__fadeIn">{{'Create Reception' | translate}}</h1>

        <div class="row animate__animated animate__fadeIn">
            <div class="field-container">
                <span class="toggle-span">{{'Reception Type' | translate}}</span>
                <button class="type-button" [ngClass]="{'disabled': selectedType != 'bpost1'}" id="button-bpost1" mat-raised-button (click)="selectType('bpost1')">Bpost1</button>
                <button class="type-button" [ngClass]="{'disabled': selectedType != 'bpost2'}" id="button-bpost2" mat-raised-button (click)="selectType('bpost2')">Bpost2</button>
                <button class="type-button" [ngClass]="{'disabled': selectedType != 'private'}" id="button-private" mat-raised-button (click)="selectType('private')">Private</button>
            </div>            
        </div>

        <div class="row animate__animated animate__fadeIn" *ngIf="selectedType == 'private'">
            <mat-form-field appearance="outline" (click)="selectProject()">
                <mat-label>{{'Project' | translate}}</mat-label>
                <input disabled matInput autocomplete="off" value="{{selectedProject}}">
                <mat-hint>{{projectsError}}</mat-hint>
            </mat-form-field>
        </div>

        <div class="row animate__animated animate__fadeInDown" *ngIf="selectedProject && areasNames.length">
            <div class="field-container">
                <span class="toggle-span">{{'Area' | translate}}</span>
                <button class="type-button" [ngClass]="{'disabled': selectedArea != area}" #area mat-raised-button *ngFor="let area of areasNames" (click)="selectArea(area)">{{area}}</button>
            </div>           
        </div>

        <div class="row animate__animated animate__fadeInDown" *ngIf="selectedProject">
            <mat-form-field  appearance="outline">
                <mat-label>{{'Barcode' | translate}}</mat-label>
                <input [(ngModel)]="barcode" matInput type="text">
            </mat-form-field>          
        </div>
             
        <div class="row animate__animated animate__fadeIn">
            <mat-form-field  appearance="outline">
                <mat-label>{{'Reception Date' | translate}}</mat-label>
                <input [(ngModel)]="creationDate" matInput type="datetime-local">
            </mat-form-field>
        </div>
        
        <div class="row buttons animate__animated animate__fadeIn">
            <button mat-raised-button color="warn" (click)="cancelButton()">{{'Cancel' | translate}}</button>
            <button mat-raised-button class="done-button" (click)="doneButton()">{{'Done' | translate}}</button>
        </div>
    </div>
    <div class="spacer"></div>
    <app-footer></app-footer>
</div>
