<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayout.xs="column"
  fxFill
>
  <span class="user"
    ><mat-icon>person</mat-icon>{{ "User" | translate }}: {{ username }}
    <span *ngIf="fullName">({{ fullName }})</span></span
  >
  <div class="spacer"></div>

  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly" fxLayout.sm="column" fxLayout.xs="row">
    <app-theme-switch fxLayout="column" fxLayoutAlign="center"></app-theme-switch>
    <mat-form-field appearance="outline" id="language-selector">
      <mat-label>{{ "Language" | translate }}</mat-label>
      <mat-select
        [(value)]="languageSelected"
        (selectionChange)="changeLanguage(languageSelected)"
      >
        <mat-option *ngFor="let language of languages" [value]="language.value">
          {{ language.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-toolbar>
