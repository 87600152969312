import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActionsComponent } from './actions/actions.component';
import { BatchComponent } from './batch/batch.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { OtherRetourComponent } from './other-retour/other-retour.component';
import { PalletComponent } from './pallet/pallet.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { ReceptionComponent } from './reception/reception.component';
import { RegLetterComponent } from './reg-letter/reg-letter.component';
import { StartComponent } from './start/start.component';

const routes: Routes = [
  {path: '', component: StartComponent},
  {path: 'pallet', component: PalletComponent},
  {path: 'batch', component: BatchComponent},
  {path: 'box', component: BatchComponent},
  {path: 'reception', component:ReceptionComponent},
  {path: 'projectSelection', component: ProjectSelectionComponent},
  {path: 'regletter', component:RegLetterComponent},
  {path: 'others', component:OtherRetourComponent},
  {path: 'data/:tableName', component:GridTableComponent},
  {path: 'actions/:searchNumber', component: ActionsComponent},
  {path: 'powerBi', component: PowerBiComponent},
  {path: 'print', component: PrintViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
