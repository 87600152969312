<div class="window">
    <app-header></app-header>
    <div class="main-container">
        <h1 *ngIf="type=='batch';else boxTitle">{{'Create Batch' | translate}}</h1>
        <ng-template #boxTitle><h1>{{'Create Box' | translate}}</h1></ng-template>

        <div class="row">
            <mat-form-field appearance="outline" (click)="selectProject()">
                <mat-label>{{'Project' | translate}}</mat-label>
                <input disabled matInput autocomplete="off" value="{{selectedProject}}">
                <mat-hint>{{projectsError}}</mat-hint>
            </mat-form-field>
        </div>

        <div class="row" *ngIf="selectedProject && areasNames.length">
            <div class="field-container">
                <span class="toggle-span">{{'Area' | translate}}</span>
                <button class="type-button" [ngClass]="{'disabled': selectedArea != area}" #area mat-raised-button *ngFor="let area of areasNames" (click)="selectArea(area)">{{area}}</button>
            </div>         
        </div>        

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>{{'Box Range' | translate}}</mat-label>
                <input autocomplete="off" [(ngModel)]="boxRange" matInput>
            </mat-form-field>
        </div>
        
        <div class="row">
            <mat-checkbox [(ngModel)]="reception" appearance="outline">{{'Assign reception' | translate }}</mat-checkbox>
        </div>

        <div class="row batch-id">
            <mat-form-field appearance="outline">
                <mat-label>{{'Batch ID' | translate}}</mat-label>
                <input autocomplete="off" [(ngModel)]="batchID" matInput type="text">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>{{'Number of' | translate}} {{type}}es</mat-label>
                <input autocomplete="off" [(ngModel)]="batchesNumber" matInput type="number">
            </mat-form-field>
        </div>
        
        <div class="row">
            <mat-form-field  appearance="outline">
                <mat-label>{{'Creation Date' | translate}}</mat-label>
                <input [(ngModel)]="creationDate" matInput type="date">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-checkbox [(ngModel)]="printView" appearance="outline">{{'Open print view at the end of creation process' | translate }}</mat-checkbox>
        </div>
        
        <div class="row buttons">
            <button mat-raised-button color="warn" (click)="cancelButton()">{{'Cancel' | translate}}</button>
            <button mat-raised-button class="done-button" (click)="doneButton()">{{'Done' | translate}}</button>
        </div>
    </div>
</div>
