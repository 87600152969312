import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-other-retour',
  templateUrl: './other-retour.component.html',
  styleUrls: ['../styles/common-styles.scss', './other-retour.component.scss'],
})
export class OtherRetourComponent implements OnInit {
  creationDate: string;
  otherTypes: any = [];
  otherTypesNames: Array<string> = [];
  selectedOther: string = '';
  numPositions: string;
  numberTotals: string = '';
  otherError = '';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.creationDate = moment().format('YYYY-MM-DDTHH:mm');
  }

  async ngOnInit() {
    var otherTypes = await this.apiService.getOtherTypes();
    if (otherTypes == 'error') {
      this.otherError = 'Error fetching other projects list';
    } else {
      this.otherTypes = otherTypes;
      this.otherTypesNames = this.otherTypes.map((project: any) => {
        return project.name;
      });
      this.selectProject();
    }
  }

  getPositions() {
    var other = this.otherTypes.find((other: any) => {
      return this.selectedOther == other.name;
    });

    if (other) this.numPositions = other.positions;
  }

  selectProject() {
    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.otherTypesNames },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result != undefined) {
        this.selectedOther = result;
        this.getPositions();
      }
    });
  }

  async doneButton() {
    var username = this.authService.getUsername();

    var others = {
      type: this.selectedOther,
      numTotal: this.numberTotals,
      creationDate: moment(this.creationDate).toISOString(),
      username: username,
    };
    
    var response = await this.apiService.sendOthers(others);

    if (response.status == '201') {
      this.cancelButton();
      this._snackBar.open(
        this.selectedOther + ' created at ' + this.creationDate,
        'Ok',
        {
          duration: this.apiService.snackbarDuration,
        }
      );
    } else {
      this._snackBar.open('Error ' + response.message, 'Ok');
    }
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
