import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { ApiService } from '../services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  searchWord: string;
  searchHint:string = "";
  breakpoint: number;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 720) ? 2 : 4;
  }

  onResize(event : any) {    
    this.breakpoint = (event.target.innerWidth <= 720) ? 2 : 4;
  }

  async searchObject(){
    if(this.searchWord == null) this.searchHint = "Enter a valid number"
    else this.router.navigate(['/actions',this.searchWord])
  }

  navigate(route:string){
    this.router.navigate([route])
  }

  openDashboard(){
    const dialogRef = this.dialog.open(DashboardModalComponent, {
      width: '100%',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  logOut(){
    if(this.cookieService.check('token')){
      this.cookieService.delete('token',"/")
      var location = window.location.href;
      var logOutWindow = window.open("https://login.input4you.be/Login/Logout", "_blank");
  
      setTimeout(()=>{
        logOutWindow!.close();
        window.location.href = location;
      },100)
    }  
  }
}
