import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService, Project } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss', '../styles/common-styles.scss'],
})
export class BatchComponent implements OnInit {
  type: string;
  batchID: string;
  creationDate: string;
  reception: boolean = false;
  printView: boolean = true;
  selectedProject: string = '';
  selectedArea: string = '';

  projects: Array<Project> = [];
  projectsNames: Array<string>;
  areasNames: Array<string>;
  boxRange: string;
  batchesNumber: number = 0;

  projectsError = '';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.type = this.router.url.split('/')[1];
    this.creationDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }

  async ngOnInit() {
    var projects = await this.apiService.getAllProjects();
    if (projects == 'error') {
      this.projectsError = 'Error fetching projects list';
    } else {
      this.projects = projects as Array<Project>;
      this.projectsNames = (projects as Array<Project>).map((project) => {
        return project.name;
      });
      this.selectProject();
    }
  }

  selectProject() {
    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.projectsNames },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result != undefined) {
        this.selectedProject = result;
        this.getAreas();
      }
    });
  }

  selectArea(area: string) {
    this.selectedArea = area;
  }

  getAreas(projectName: string = this.selectedProject) {
    this.areasNames = [];
    this.projects.forEach((project: Project) => {
      if (project.name == projectName && project.area != '') {
        this.areasNames.push(project.area);
      }
    });
    this.selectedArea = this.areasNames[0];
  }

  async doneButton() {
    var username = this.authService.getUsername();

    var batch = {
      project: this.selectedProject,
      area: this.selectedArea,
      batchID: this.batchID,
      creationDate: this.creationDate,
      username: username,
      type: this.type,
    };

    // var response = await this.apiService.sendBatch(batch);

    // if(response.status == "201"){
    //   this.cancelButton();
    //   this._snackBar.open(this.selectedProject + " "+this.type+" created at " + this.creationDate, "Ok", {
    //     duration: this.apiService.snackbarDuration
    //   });
    // }
    // else{
    //   this._snackBar.open("Error " + response.message, "Ok");
    // }

    if (this.printView) {
      this.router.navigate(['print'], {
        queryParams: {
          batchId: this.batchID,
          date: this.creationDate,
          totalBatches: this.batchesNumber,
          user: username,
          project: this.selectedProject,
        },
      });
    }
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
