<div fxLayout="column" fxFill>
  <app-header [showLogout]="false"></app-header>

  <div fxLayout="row" fxLayoutAlign="center center">
    <canvas id="barcode" style="page-break-after: always"></canvas>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2><strong>Project: </strong> {{ project }}</h2>
    <h2><strong>Creation date: </strong> {{ creationDate }}</h2>
    <h2><strong>Total batches: </strong> {{ totalBatches }}</h2>
    <h2><strong>Operator: </strong> {{ username }}</h2>
  </div>

  <div class="spacer"></div>
  <div fxLayout="row" fxLayoutAlign="end end" media="print">
    <div class="btn-container">
      <button mat-fab color="primary" (click)="printScreeen()">
        <mat-icon>print</mat-icon>
      </button>
    </div>
  </div>
</div>
