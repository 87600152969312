import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ProgressModalComponent } from '../progress-modal/progress-modal.component';
import * as moment from 'moment';
import { Others } from '../grid-table/interfaces/grid.interface';

export interface Project {
  area: string;
  client: string;
  hasRegLetter: boolean;
  id: number;
  initials: string;
  name: string;
  receptionType: Array<any>;
  receptions: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  objectData: any = null;
  projectsList: Array<Project> = null;
  bpost1Projects: Array<Project> = [];
  regLetterProjects: Array<Project> = [];
  privateProjects: Array<Project> = [];
  otherProjects: Array<any> = null;
  snackbarDuration = 10000;

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  async searchByPalletBoxBatch(number: string) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const url = environment.atelierController + 'api/Batches/' + number;

    return await this.http
      .get(url)
      .toPromise()
      .then((data) => {
        this.objectData = data;
        dialogRef.close();
        return this.objectData;
      })
      .catch((error) => {
        console.log('[ApiService] searchByPalletBoxBatch: \n', error);
        dialogRef.close();
        return error;
      });
  }

  async getSavedObject(number: string) {
    if (this.objectData != null) return this.objectData;
    else return await this.searchByPalletBoxBatch(number);
  }

  async getAllProjects() {
    if (this.projectsList) return this.projectsList;
    else {
      const dialogRef = this.dialog.open(ProgressModalComponent);
      const url = environment.atelierController + 'api/Projects';

      return await this.http
        .get(url)
        .toPromise()
        .then((data: any) => {
          this.projectsList = this.orderList(data);
          this.processProjectsLists(this.projectsList);
          dialogRef.close();
          return this.projectsList;
        })
        .catch((error) => {
          dialogRef.close();
          console.error('[ApiService] getAllProjects: \n', error);
          return 'error';
        });
    }
  }

  processProjectsLists(allProjects: Array<Project>) {
    allProjects.forEach((project) => {
      if (project.receptionType.find((type) => type.name == 'Bpost1'))
        this.bpost1Projects.push(project);
      if (project.receptionType.find((type) => type.name == 'Private'))
        this.privateProjects.push(project);
    });
  }

  orderList(list: Array<Project>) {
    var orderedList = list.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return orderedList;
  }

  async getRegLetterProjects(): Promise<Project[]> {
    if (this.projectsList != null) return this.regLetterProjects;
    else {
      const url = environment.atelierController + 'api/Projects/Type/bpost2';
      const dialogRef = this.dialog.open(ProgressModalComponent);
      return await this.http
        .get(url)
        .toPromise()
        .then((data: any) => {
          this.regLetterProjects = data;
          dialogRef.close();
          return data;
        })
        .catch((error) => {
          console.error('[ApiService] getRegLetterProjects: \n', error);
          dialogRef.close();
          return [];
        });
    }
  }

  async getBpostProjects() {
    if (this.projectsList != null) return this.bpost1Projects;
    else {
      await this.getAllProjects();
      return this.bpost1Projects;
    }
  }

  async getPrivateProjects() {
    if (this.projectsList != null) return this.privateProjects;
    else {
      await this.getAllProjects();
      return this.privateProjects;
    }
  }

  async getOtherTypes() {
    if (this.otherProjects) return this.otherProjects;
    else {
      const dialogRef = this.dialog.open(ProgressModalComponent);
      const url = environment.atelierController + 'api/OtherTypes';

      return await this.http
        .get(url)
        .toPromise()
        .then((data: any) => {
          this.otherProjects = data;
          dialogRef.close();
          return data;
        })
        .catch((error) => {
          dialogRef.close();
          console.error('[ApiService] getOtherTypes: \n', error);
          return 'error';
        });
    }
  }

  async getReceptionsByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/Receptions/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getReceptions: \n', error);
        return [];
      });
  }

  async getOthersByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/Others/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get<Others[]>(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getOthers: \n', error);
        return [];
      });
  }

  async getRegLettersByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/RegLetters/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getRegLetters: \n', error);
        return [];
      });
  }

  async getTodayBpost1() {
    const url =
      environment.atelierController + 'api/Receptions/GetTodayBpostReception';

    return await this.http
      .get(url)
      .toPromise()
      .then((data: any) => {
        return data || undefined;
      })
      .catch((error) => {
        console.error('[ApiService] getTodayBpost1: \n', error);
        return error;
      });
  }

  async updateBpost1Projects(projects: any) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const url =
      environment.atelierController + 'api/Receptions/UpdateBpostProjects';

    return await this.http
      .post(url, projects, { observe: 'response' })
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] updateBpost1Projects: \n', error);
        return error;
      });
  }

  async sendBatch(json: any) {
    const url = environment.atelierController + 'api/Batches';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendBatch: \n', error);
        return error;
      });
  }

  async sendRegLetter(json: any) {
    const url = environment.atelierController + 'api/RegLetters';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendRegLetter: \n', error);
        return error;
      });
  }

  async sendReception(json: any) {
    const url = environment.atelierController + 'api/Receptions';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendRegLetter: \n', error);
        return error;
      });
  }

  async sendOthers(json: any) {
    const url = environment.atelierController + 'api/Others';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendOthers: \n', error);
        return error;
      });
  }

  async deleteBatch(id: string) {
    const url = environment.atelierController + 'api/Batches/' + id;

    return await this.http
      .delete(url, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] deleteBatch: \n', error);
        return error;
      });
  }
}
