<div class="window">
  <app-header></app-header>
  <div class="content">
    <div class="input-menu">
      <mat-form-field
        appearance="outline"
        (click)="selectProject()"
        class="animate__animated animate__fadeIn"
      >
        <mat-label>{{ "Project" | translate }}</mat-label>
        <input
          disabled
          matInput
          autocomplete="off"
          value="{{ selectedProject }}"
        />
        <mat-hint>{{ projectsError }}</mat-hint>
      </mat-form-field>

      <div
        class="field-container animate__animated animate__fadeIn"
        *ngIf="selectedProject && areasNames.length"
      >
        <span class="toggle-span">{{ "Area" | translate }}</span>
        <button
          class="area-button"
          [ngClass]="{ disabled: selectedArea != area }"
          #area
          mat-raised-button
          *ngFor="let area of areasNames"
          (click)="selectArea(area)"
        >
          {{ area }}
        </button>
      </div>

      <mat-form-field
        appearance="outline"
        class="animate__animated animate__fadeIn"
      >
        <mat-label>Batch Number</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="objectID" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        *ngIf="selectedProject"
        class="animate__animated animate__fadeIn"
      >
        <mat-label>{{ "Client" | translate }}</mat-label>
        <input matInput [value]="selectedClient" disabled />
      </mat-form-field>

      <div class="barcode-input">
        <mat-form-field
          appearance="outline"
          class="animate__animated animate__fadeIn"
        >
          <mat-label>Barcode</mat-label>
          <input
            matInput
            id="barcode"
            #barcode
            autocomplete="off"
            (keyup.enter)="addBarcode(barcode.value)"
          />
        </mat-form-field>

        <button
          id="add-button"
          mat-raised-button
          color="primary"
          (click)="addBarcode(barcode.value)"
          class="animate__animated animate__fadeIn"
        >
          <span class="material-icons-two-tone"> add </span>
          {{ "Add" | translate }}
        </button>
      </div>
    </div>

    <div class="barcode-list animate__animated animate__fadeIn">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="element">
          <th mat-header-cell *matHeaderCellDef>{{ "element" | translate }}</th>
          <td mat-cell *matCellDef="let i = index">{{ i }}</td>
        </ng-container>

        <ng-container matColumnDef="barcode">
          <th mat-header-cell *matHeaderCellDef>{{ "barcode" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>{{ "options" | translate }}</th>
          <td mat-cell *matCellDef="let i = index">
            <button mat-raised-button color="warn" (click)="removeBarcode(i)">
              <span class="material-icons-two-tone remove-icon"> delete </span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="animate__animated animate__fadeIn"
        ></tr>
      </table>
    </div>
  </div>

  <div class="toolbar">
    <button
      mat-raised-button
      class="cancel-button"
      color="warn"
      (click)="cancelButton()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      mat-raised-button
      class="save-button"
      color="primary"
      (click)="savePicking()"
    >
      Save
    </button>
  </div>
</div>
