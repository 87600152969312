import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-theme-switch',
    templateUrl: './theme-switch.component.html',
    styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent implements OnInit{

    private static readonly DARK_THEME_CLASS = 'dark-theme';
    private static readonly DARK_THEME_LIGHT = 'light';
    private static readonly DARK_THEME_DARK = 'dark';
    private static readonly DEFAULT_THEME = ThemeSwitchComponent.DARK_THEME_LIGHT;

    public theme: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService) {
        this.theme = this.document.documentElement.classList.contains(ThemeSwitchComponent.DARK_THEME_CLASS) ? ThemeSwitchComponent.DARK_THEME_DARK : ThemeSwitchComponent.DARK_THEME_LIGHT;
    }

    ngOnInit(){
        if(this.cookieService.check('theme')){
            this.selectTheme(this.cookieService.get('theme'));
        }
        else{
            this.selectTheme(ThemeSwitchComponent.DEFAULT_THEME);
        }
    }

    selectTheme(theme: string){
        if(theme == "dark"){
            this.selectDarkTheme();
        }
        else if(theme == "light"){
            this.selectLightTheme();
        }
    }

    public selectDarkTheme(): void {
        this.document.documentElement.classList.add(ThemeSwitchComponent.DARK_THEME_CLASS);
        this.theme = ThemeSwitchComponent.DARK_THEME_DARK;
        this.cookieService.set('theme',this.theme ,{path:'/'})
    }

    public selectLightTheme(): void {
        this.document.documentElement.classList.remove(ThemeSwitchComponent.DARK_THEME_CLASS);
        this.theme = ThemeSwitchComponent.DARK_THEME_LIGHT;
        this.cookieService.set('theme',this.theme ,{path:'/'})
    }
}