<div fxLayout="column" fxFill>
  <app-header></app-header>
  <iframe
    class="responsive-iframe"
    title="ATELIER  - DETAIL REPORT"
    src="https://app.powerbi.com/reportEmbed?reportId=ffb8ca0e-099a-44b2-993d-2e08c95881f1&autoAuth=true&ctid=0d839e25-fe47-4d7b-8210-cc1cf5948c63&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
    frameborder="0"
    allowFullScreen="true"
  ></iframe>
</div>
