import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletionConfirmationComponent } from '../deletion-confirmation/deletion-confirmation.component';
import { ApiService } from '../services/api.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface HistoryElement{
  project:string,
  username:string,
  elements:number,
  active:boolean,
  status:string,
  endtime:string,
  physical:boolean,
  batchID:string,
  batchHash:string,
  scannedDate:string,
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

const HISTORY_DATA: HistoryElement[] = [
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:true,elements:20,status:"TRANSFERREED",endtime:"02/08/2021 05:04",physical:false,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"},
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:true,elements:20,status:"REGISTERED",endtime:"02/08/2021 05:04",physical:true,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"},
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:false,elements:20,status:"REGISTERED",endtime:"02/08/2021 05:04",physical:false,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"},
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:true,elements:20,status:"REGISTERED",endtime:"02/08/2021 05:04",physical:false,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"},
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:true,elements:20,status:"REGISTERED",endtime:"02/08/2021 05:04",physical:false,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"},
  {project:"DKV",username:"Registration DOCTOOLS03-DKVUser",active:true,elements:20,status:"REGISTERED",endtime:"02/08/2021 05:04",physical:false,batchID:"202108020051",batchHash:"9913c1abe4bfde4b171a3b824bdd605a85ca1866d26751fa971bd1f052ee12d0",scannedDate:"02/08/2021 04:59"}
];

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  displayedColumns = Object.keys(HISTORY_DATA[0]);
  dataSource = HISTORY_DATA;
  searchNumber:string;
  searchMessage="";
  objectData:any = null;

  constructor(
    private apiService:ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      this.searchNumber = params.get('searchNumber')
      this.getObjectInformation();
    })
  }

  async getObjectInformation(number = this.searchNumber){
    var response = await this.apiService.searchByPalletBoxBatch(number)
    if(response.error) this.searchMessage = response.status + ": " + response.statusText;
    else this.objectData = response
  }

  showHideHistory(){
    var element = document.getElementById("history")
    var display = element.style.display

    if(display != "none" && display != "") element.style.display = "none"
    else element.style.display = "block"
  }

  deleteBatch(){
    const dialogRef = this.dialog.open(DeletionConfirmationComponent, {
      width: '100%',
      data: this.objectData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true) this.apiService.deleteBatch(this.objectData.batchNumber);
    });
  }

  printButton(){
    print();
  }

  cancelButton(){
    this.router.navigate(['/'])
  }

}
