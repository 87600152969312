import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.scss']
})
export class PowerBiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
