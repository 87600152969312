import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.scss']
})
export class PalletComponent implements OnInit {

  palletID:string;
  project:string;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  doneButton(){
    
  }

  cancelButton(){
    this.router.navigate(['/'])
  }

}
