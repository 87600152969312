<div mat-dialog-content>
    <button class="home-button  mat-elevation-z3" (click)="navigate('reception')">
        <div class="button-content">
            <span class="material-icons-two-tone icon">
                local_shipping
            </span>
            <span>{{'Receptions' | translate}}</span>
        </div>        
    </button>
    <button class="home-button  mat-elevation-z3" (click)="navigate('regLetter')">
        <div class="button-content">

        <span class="material-icons-two-tone icon">
            fact_check
        </span>
        <span>{{'Registration Letter' | translate}}</span>
        </div>
    </button>
    <button class="home-button  mat-elevation-z3" (click)="navigate('others')">
        <div class="button-content">

        <span class="material-icons-two-tone icon">
            list_alt
        </span>
        <span>{{'Others' | translate}}</span>
        </div>
    </button>
</div>