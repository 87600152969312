import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService, Project } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss', '../styles/common-styles.scss'],
})
export class ReceptionComponent implements OnInit {
  projects: Array<Project> = [];
  projectsNames: any;
  areasNames: Array<string>;
  selectedProject: string;
  selectedArea: string;
  selectedType: string;
  creationDate: string;
  barcode: string;
  projectsError = '';
  todayBpostReception: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.creationDate = moment().format('YYYY-MM-DDTHH:mm');;
    this.todayBpostReception = await this.apiService.getTodayBpost1();

    var projects = await this.apiService.getPrivateProjects();
    if (projects.length == 0) {
      this.projectsError = "There aren't private projects";
    } else {
      this.projects = projects;
      this.projectsNames = projects.map((project) => {
        return project.name;
      });
    }
  }

  selectProject() {
    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.projectsNames },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result != null && result != undefined) {
        this.selectedProject = result;
        this.getAreas();
      }
    });
  }

  getAreas(projectName: string = this.selectedProject) {
    this.areasNames = [];
    this.projects.forEach((project: Project) => {
      if (project.name == projectName && project.area != '') {
        this.areasNames.push(project.area);
      }
    });
    this.selectArea(this.areasNames[0]);
  }

  selectArea(area: string) {
    this.selectedArea = area;
  }

  selectType(type: string) {
    this.selectedType = type;

    if (this.selectedType == 'private') this.selectProject();
  }

  async doneButton() {
    var username = this.authService.getUsername();

    if (
      this.selectedType === 'bpost1' &&
      this.todayBpostReception !== undefined
    ) {
      this._snackBar.open(
        this.selectedType + ' already exits at ' + this.creationDate,
        'Ok',
        {
          duration: this.apiService.snackbarDuration,
        }
      );

      return;
    }

    var reception = {
      project: this.selectedProject,
      area: this.selectedArea,
      creationDateTime: moment(this.creationDate).toISOString(),
      username: username,
      barcode: this.barcode,
      type: this.selectedType,
    };

    var response = await this.apiService.sendReception(reception);

    if (response.status == '201') {
      this.cancelButton();
      this._snackBar.open(
        this.selectedType + ' reception created at ' + this.creationDate,
        'Ok',
        {
          duration: this.apiService.snackbarDuration,
        }
      );
    } else {
      this._snackBar.open('Error: ' + response.error, 'Ok');
    }
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
