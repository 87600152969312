<div class="window">
    <div class="pallet-container">
        <h1>{{'Create Pallet' | translate}}</h1>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>{{'Project' | translate}}</mat-label>
                <mat-select [(ngModel)]="project">
                    <mat-option value="AZSLBRU">AZSLBRU</mat-option>
                    <mat-option value="DKV">DKV</mat-option>
                    <mat-option value="ETHIASSDS">ETHIASSDS</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>{{'Pallet ID' | translate}}</mat-label>
                <input autocomplete="off" [(ngModel)]="palletID" matInput type="text">
            </mat-form-field>
        </div>

        <div class="row buttons">
            <button mat-raised-button color="primary" (click)="doneButton()">{{'Done' | translate}}</button>
            <button mat-raised-button color="warn" (click)="cancelButton()">{{'Cancel' | translate}}</button>
        </div>
    </div>
</div>
