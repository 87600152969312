<div class="window" fxLayout="column" fxLayoutGap="20px" fxFlexFill>
    <app-header></app-header>
    <div class="main-container">
        <h1 class="animate__animated animate__fadeIn">{{'Totals & Retour' | translate}}</h1>

        <div class="mat-headline row animate__animated animate__fadeIn">
            <mat-form-field appearance="outline" (click)="selectProject()">
                <mat-label>{{'Other Project' | translate}}</mat-label>
                <input disabled matInput autocomplete="off" value="{{selectedOther}}">
                <mat-hint>{{otherError}}</mat-hint>
            </mat-form-field>
        </div>

        <div class="mat-headline row animate__animated animate__fadeIn" *ngIf="selectedOther && numPositions">
            <mat-form-field  appearance="outline">
                <mat-label>{{'Number of totals' | translate}}</mat-label>
                <input [(ngModel)]="numberTotals" autocomplete="off" maxlength="{{numPositions}}" matInput type="text" pattern="\d*">
            </mat-form-field>           
        </div>        
        
        <div class="mat-headline row animate__animated animate__fadeIn">
            <mat-form-field  appearance="outline">
                <mat-label>{{'Creation Date' | translate}}</mat-label>
                <input [(ngModel)]="creationDate" matInput type="datetime-local">
            </mat-form-field>
        </div>
        
        <div class="row buttons animate__animated animate__fadeIn animate__delay-2s">
            <button mat-raised-button color="warn" (click)="cancelButton()">{{'Cancel' | translate}}</button>
            <button mat-raised-button class="done-button" (click)="doneButton()">{{'Done' | translate}}</button>
        </div>
    </div>
    <div class="spacer"></div>
    <app-footer></app-footer>
</div>
