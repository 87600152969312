import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selection-modal',
  templateUrl: './selection-modal.component.html',
  styleUrls: ['./selection-modal.component.scss']
})
export class SelectionModalComponent{

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<SelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {list:Array<string>}
  ) {
    data.list = data.list.filter(function(item, pos, self) {
      return self.indexOf(item) == pos;
    })
  }

  selectProject(project:string){
    this.dialogRef.close(project);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/'])
  }

}
