import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss']
})
export class DashboardModalComponent {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<DashboardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{},
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  navigate(table:string){
    this.dialogRef.close()
    this.router.navigate(['/data',table])
  }
}
