import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() folderHash:string = "";
  environment = environment;
  languageSelected:string = "en";
  username:string = "";
  fullName:string = "";

  languages: Language[] = [
    {value: 'en', viewValue: 'English'},
    {value: 'nl', viewValue: 'Dutch'},
    {value: 'fr', viewValue: 'French'}
  ];

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private auth: AuthService
    ) { }

  ngOnInit(): void {
    this.languageSelected = this.cookieService.get('Language')
    this.username = this.auth.getUsername() || "";
    this.fullName = this.auth.getFullName() || "";
  }

  changeLanguage(lan:string){
    this.cookieService.set('Language',lan,{path:'/'})
    this.translate.setDefaultLang(lan)
    this.translate.use(lan)
  }

}
