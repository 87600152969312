<div class="window">
  <app-header></app-header>

  <div class="content">
    <h1>{{ "Bpost1 Project Selection" | translate }}</h1>

    <div class="project-grid">
      <button
        id="{{ project.name }}"
        class="project-button animate__animated animate__fadeIn"
        color="primary"
        mat-raised-button
        [disabled]="disableAllButtons"
        (click)="changeClass(project.name, project.area)"
        [ngClass]="{ disabled: !project.selected }"
        *ngFor="let project of projectList"
      >
        {{ project.name }} {{ project.area }}
      </button>
    </div>
  </div>

  <div class="buttons-container">
    <button
      class="cancel-button"
      mat-raised-button
      color="warn"
      (click)="cancelButton()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      class="save-button"
      mat-raised-button
      (click)="selectBpostProjects()"
    >
      {{ "Save" | translate }}
    </button>
  </div>
</div>
