<div class="window">
    <div class="header">
       <app-header [objectData]="objectData"></app-header> 
    </div>
    <div class="body" *ngIf="objectData;else searchError">
        <div class="body-container">
            <div class="row action-title">
                <span>{{'NEXT' | translate}}: Start Preparation</span>                
            </div>
            <div class="row action-confirmation">
                <button mat-raised-button (click)="cancelButton()" color="warn">{{'Cancel' | translate}}</button>
                <button mat-raised-button class="accept-button" color="primary">{{'Ok' | translate}}</button>
            </div>
            <div class="row action-buttons">
                <mat-selection-list [multiple]="false" class="inline-list" appaerance="outline">
                    <mat-list-option (click)="printButton()">
                        <span class="material-icons-two-tone icon">print</span>
                        {{'Print' | translate}} 
                    </mat-list-option>
                    <mat-list-option (click)="deleteBatch()">
                        <span class="material-icons-two-tone icon">delete</span> 
                        {{'Delete' | translate}} {{objectData.type}}
                    </mat-list-option>
                    <mat-list-option>
                        <span class="material-icons-two-tone icon">post_add</span> 
                        {{'Remark' | translate}} 
                    </mat-list-option>
                    <mat-list-option>
                        <span class="material-icons-two-tone icon">add_box</span>
                        {{'Add to pallet' | translate}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <!-- <div class="row">
                <button mat-stroked-button color="primary" (click)="showHideHistory()">
                    <span *ngIf="history.style.display == '' || history.style.display == 'none';else hideHistory">{{'Show history' | translate}}</span>
                    <ng-template #hideHistory>{{'Hide history' | translate}}</ng-template>
                </button>
                <div #history id="history">
                    <table mat-table [dataSource]="dataSource" class="history-table mat-elevation-z8">

                        <ng-container matColumnDef="project">
                          <th mat-header-cell *matHeaderCellDef>{{'Project' | translate}}</th>
                          <td mat-cell *matCellDef="let element"> {{element.project}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="username">
                          <th mat-header-cell *matHeaderCellDef> {{'username' | translate}} </th>
                          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="elements">
                          <th mat-header-cell *matHeaderCellDef> {{'elements' | translate}} </th>
                          <td mat-cell *matCellDef="let element"> {{element.elements}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="active">
                          <th mat-header-cell *matHeaderCellDef> {{'active' | translate}} </th>
                          <td mat-cell *matCellDef="let element"> <mat-checkbox [checked]="element.active"></mat-checkbox></td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> {{'status' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="endtime">
                            <th mat-header-cell *matHeaderCellDef> {{'endtime' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.endtime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="physical">
                            <th mat-header-cell *matHeaderCellDef> {{'physical' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> <mat-checkbox [checked]="element.physical"></mat-checkbox></td>
                        </ng-container>
                        
                        <ng-container matColumnDef="batchID">
                            <th mat-header-cell *matHeaderCellDef> {{'batchID' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.batchID}} </td>
                        </ng-container>

                        <ng-container matColumnDef="batchHash">
                            <th mat-header-cell *matHeaderCellDef> {{'batchHash' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.batchHash}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="scannedDate">
                            <th mat-header-cell *matHeaderCellDef> {{'scannedDate' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.scannedDate}} </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                </div>
            </div> -->
        </div>        
    </div>

    <ng-template  #searchError>
        <div class="search-error">
            {{searchMessage}}
        </div>
    </ng-template>
</div>
