import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, Project } from '../services/api.service';

@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss'],
})
export class ProjectSelectionComponent implements OnInit {
  projects: Array<Project>;
  todayBpostReception: any;
  projectList: Array<any> = [];
  selectedReception: string = 'Bpost1';
  receptionTypes: any;
  disableAllButtons: boolean = true;

  constructor(private apiService: ApiService, private router: Router) {}

  async ngOnInit() {
    this.projects = await this.apiService.getBpostProjects();
    this.todayBpostReception = await this.apiService.getTodayBpost1();

    if (this.projects.length) {
      this.projectList = this.projects.map((project) => {
        return { name: project.name, area: project.area, selected: true };
      });
    }

    console.log(this.todayBpostReception.projects);

    if (this.todayBpostReception !== undefined) {
      this.disableAllButtons = false;

      if (this.todayBpostReception.projects.length) {
        this.projectList.forEach((project) => {
          let hasProject: boolean = this.todayBpostReception.projects.find(
            (defaultProject: any) => {
              return (
                defaultProject.name === project.name &&
                defaultProject.area === project.area
              );
            }
          );
  
          if (!hasProject) {
            project.selected = false;
          }
        });
      }
    }
  }

  changeClass(projectName: string, projectArea: string) {
    var project = this.projectList.find((project) => {
      return project.name === projectName && project.area === projectArea;
    });

    project.selected = !project.selected;
  }

  selectBpostProjects() {
    var selectedProjects = this.projectList.filter((project) => {
      return project.selected;
    });

    var projects = selectedProjects.map((project) => {
      return { name: project.name, area: project.area };
    });

    var json = {
      data: projects,
    };

    this.apiService.updateBpost1Projects(json);
    this.cancelButton();
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
