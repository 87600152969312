import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface AdUser {
  Name:string,
  LastName:string,
  SAMName:string,
  PermisionGroups:[{GroupName:string,SAMName:string}],
  MembershipGroup:[{GroupName:string}]
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private aduser:AdUser = null;

  constructor(private http: HttpClient) { }

  login(name:string){
    const url: string = environment.loginUrl + "LoginPin?redirectUri="+name;
    window.location.href = url;
  }

  async authorize(token:string):Promise<any>{
    const url: string = environment.loginUrl + "Authorize?token=" + token;

    return await this.http.post(url, "")
      .toPromise()
      .then((data:any) => {
        this.aduser = data.aduser;
        return data;
      }).catch(error => {
        console.error("[AuthService] authorize(token) - ERROR obtaining authorize of token \n", error);
      });
  }

  getUsername(){
    if(this.isAuthenticated()){
      return this.aduser.SAMName;
    }
    else return null;
  }

  getFullName(){
    if(this.isAuthenticated()){
      return this.aduser.Name + " " + this.aduser.LastName;
    }
    else return null;
  }

  isAuthenticated(){
    return this.aduser != null;
  }

  public hasPermissionForProject(project:string, user:AdUser = this.aduser){
    if(user != null && !this.isNullOrWhitespace(project)){
      if(this.isCurrentUserClient()){
        return user.PermisionGroups.some(p => p.SAMName.includes(project))
      }
      else if(this.isCurrentUserDevelopmentTeamMember() || this.isCurrentUserQualityControllMember()){
        return true;
      }
      else{   
        return user.PermisionGroups.some(p => p.GroupName.includes("Administrator"));
      }  
    }
    else return false;
  }

  isNullOrWhitespace( input:string ) {
    if (input === undefined || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  getRole(user = this.aduser){
    if(this.isCurrentUserDevelopmentTeamMember(user)){
      return "developer"
    }
    else if(this.isCurrentUserClient(user)){
      return "costumer"
    }
    else return "other"
  }

  isCurrentUserClient(user = this.aduser){
      return user.PermisionGroups.some(group => group.GroupName.toLowerCase().includes("client"));
  }
  isCurrentUserQualityControllMember(user = this.aduser){
      return user.MembershipGroup.some(group => group.GroupName == "InputForYouQCTeam");
  }
  isCurrentUserDevelopmentTeamMember(user = this.aduser){
      return user.MembershipGroup.some(group => group.GroupName == "InputForYouDevTeam");
  }
  isCurrentUserBPO(){
      return !this.isCurrentUserClient() && !this.isCurrentUserQualityControllMember() && !this.isCurrentUserDevelopmentTeamMember(); 
  }
}
