import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss'],
})
export class PrintViewComponent implements OnInit, AfterViewInit {
  barcodeNumber: string;
  project: string = '';
  totalBatches: string;
  creationDate: string | Date;
  username: String;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.barcodeNumber = params['batchId'];
      this.project = params['project'];
      this.creationDate = params['date'];
      this.totalBatches = params['totalBatches'];
      this.username = params['user'];
    });
  }

  ngAfterViewInit(): void {
    JsBarcode('#barcode', this.barcodeNumber);
  }

  printScreeen() {
    window.print();
  }
}
