<div fxLayout="column">
  <app-header></app-header>
  <div class="content">
    <div class="header">
      <mat-button-toggle-group
        #dataSelector
        class="dataSelector"
        name="dataSelector"
        (change)="navigate($event.value)"
      >
        <div fxLayout="row" fxLayout.xs="column" fxFill>
          <mat-button-toggle value="reception" [checked]="type == 'reception'">
            <span class="material-icons-two-tone icon"> local_shipping </span>
            {{ "Receptions" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="regLetter" [checked]="type == 'regLetter'">
            <span class="material-icons-two-tone icon"> fact_check </span>
            {{ "Registration Letter" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="others" [checked]="type == 'others'">
            <span class="material-icons-two-tone icon"> list_alt </span>
            {{ "Others" | translate }}
          </mat-button-toggle>
        </div>
      </mat-button-toggle-group>
    </div>

    <mat-form-field appearance="fill" class="dateSelection">
      <mat-label>{{ "Start date" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="start"
        placeholder="Start time"
        (dateInput)="onDateInput('start', $event)"
        [value]="startDate"
      />
      <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
      <mat-datepicker #start></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="dateSelection">
      <mat-label>{{ "End date" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="end"
        placeholder="End time"
        (dateInput)="onDateInput('end', $event)"
        [value]="endDate"
        [min]="startDate"
      />
      <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
      <mat-datepicker #end></mat-datepicker>
    </mat-form-field>

    <ag-grid-angular
      *ngIf="rowData"
      class="grid"
      [ngClass]="theme == 'light' ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      rowSelection="multiple"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)"
    >
    </ag-grid-angular>

    <button
      class="gridButton"
      mat-raised-button
      color="primary"
      aria-label="Export to CSV"
      (click)="exportToCSV()"
    >
      {{ "Export to CSV" | translate }}
    </button>

    <button
      *ngIf="totalRowsSelected > 0"
      class="gridButton"
      mat-raised-button
      color="primary"
      aria-label="Export selected to CSV"
      (click)="exportToCSV(true)"
    >
      {{ "Export selected to CSV" | translate }}
    </button>
  </div>
  <app-footer></app-footer>
</div>
