<div fxLayout="column" fxLayoutGap="20px" fxFlexFill class="window">
  <app-header [showSearch]="true"></app-header>
  <div class="nav-menu">
    <mat-grid-list
      class="big-grid"
      [cols]="breakpoint"
      rowHeight="80px"
      gutterSize="20"
      (window:resize)="onResize($event)"
    >
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate('reception')"
      >
        <span class="material-icons-two-tone icon"> local_shipping </span>
        {{ "New Reception" | translate }}
      </mat-grid-tile>
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate('regletter')"
      >
        <span class="material-icons-two-tone icon"> fact_check </span>
        {{ "Registered Letters" | translate }}
      </mat-grid-tile>
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate('projectSelection')"
      >
        <span class="material-icons-two-tone icon"> pending_actions </span>
        {{ "Bpost projects" | translate }}
      </mat-grid-tile>
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate('others')"
      >
        <span class="material-icons-two-tone icon"> list_alt </span>
        {{ "Totals" | translate }}
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list class="small-grid" cols="3" rowHeight="80px" gutterSize="20">
      <!-- <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="1"
        (click)="navigate('pallet')"
      >
        <span class="material-icons-two-tone icon"> grid_on </span>
        {{ "New Pallet" | translate }}
      </mat-grid-tile> -->
      <!-- <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="1"
        (click)="navigate('box')"
      >
        <span class="material-icons-two-tone icon"> inventory_2 </span>
        {{ "New Box" | translate }}
      </mat-grid-tile> -->
      <!-- <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="1"
        (click)="navigate('batch')"
      >
        <span class="material-icons-two-tone icon"> layers </span>
        {{ "New Batch" | translate }}
      </mat-grid-tile> -->
      <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="3"
        (click)="openDashboard()"
      >
        <span class="material-icons-two-tone icon"> dashboard </span>
        {{ "Dashboard" | translate }}
      </mat-grid-tile>
      <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="3"
        (click)="navigate('powerBi')"
      >
        <span class="material-icons-two-tone icon"> assessment </span>
        {{ "Reports" | translate }}
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="spacer"></div>
  <app-footer></app-footer>
</div>
