<div class="search-bar-row">
    <div class="logo animate__animated animate__fadeIn" (click)="goBack()"><img src="../../assets/logo.png"></div>
    <div class="project-info" *ngIf="objectData">
        {{objectData.project}}<span *ngIf="objectData.palletId">&nbsp;&gt; <a>{{objectData.palletId}}</a></span>&nbsp; &gt; {{objectData.batchID}}
    </div>
    <button class="log_out animate__animated animate__fadeIn" color="warn" mat-raised-button (click)="logOut()" *ngIf="showLogout">
        <span class="material-icons-two-tone">
            logout
        </span>
        {{'Log Out' | translate}}
    </button>

    <!-- <div class="element animate__animated animate__fadeInRight" *ngIf="showSearch">
        <button class="search-button" mat-raised-button color="primary" aria-label="Search" (click)="searchObject()">
            <span class="material-icons-two-tone search-icon">search</span>
            {{'Search' | translate}}
        </button>
        <mat-form-field class="search-bar" appearance="outline">
            <input [(ngModel)]="searchWord" autocomplete="off" matInput type="text" (keydown.enter)="searchObject()"
            placeholder="{{'Enter Pallet, Box or Batch Barcode' | translate}}">
            <mat-hint>{{searchHint}}</mat-hint>
        </mat-form-field>        
    </div>   -->
</div>
